<script setup lang="ts">
  import { useSubmitChangeOfTenancyMoveIn } from "@/api/change-of-tenancy/change-of-tenancy"
  import { ExternalRoute } from "@/constants/externalRoute"
  import { Route } from "@/constants/route"
  import { formatDate } from "@/lib/dateHelper"
  import { useCotMoveIn } from "@/lib/useCotMoveIn"
  import { useStepWizard } from "@/lib/useStepWizard"
  import { formatCurrency, maskAllExceptLast2 } from "@/lib/utils.ts"
  import TrackingService from "@/services/TrackingService"
  import dayjs from "dayjs"
  import advancedFormat from "dayjs/plugin/advancedFormat"
  import customParseFormat from "dayjs/plugin/customParseFormat"
  import { computed } from "vue"
  import { useRouter } from "vue-router"
  import { Alert } from "@/components/ui/alert"
  import { Button } from "@/components/ui/button"
  import { Link } from "@/components/ui/link"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"

  type StepInformation = {
    title: string
    editStep?: number
    content: {
      title?: string
      text: string
    }[]
  }

  dayjs.extend(customParseFormat)
  dayjs.extend(advancedFormat)

  const { goToStep } = useStepWizard()
  const {
    cotMoveInRequest,
    moveInAddress,
    newOccupantContact,
    directDebitInfo,
    cotMoveInExtras,
  } = useCotMoveIn()

  const information = computed<StepInformation[]>(() => [
    {
      title: "New Address",
      editStep: 0,
      content: [
        {
          title: "Address",
          text: cotMoveInExtras.value.fullAddress,
        },
      ],
    },
    {
      title: "Moving in Date",
      editStep: 1,
      content: [
        {
          title: "First date of responsibility",
          text: formatDate(
            cotMoveInRequest.value.firstDateOfResponsibility,
            "ordinal",
            "default",
          ),
        },
      ],
    },
    {
      title: "New Occupant Information",
      editStep: 2,
      content: [
        {
          title: "Name",
          text: `${newOccupantContact.value.firstName} ${newOccupantContact.value.lastName}`,
        },
        { title: "Date of birth", text: newOccupantContact.value.dateOfBirth },
        { title: "Email", text: newOccupantContact.value.email },
        { title: "Phone", text: newOccupantContact.value.phone1 },
      ],
    },
    {
      title: "Monthly Payment",
      editStep: 3,
      content: [
        {
          title: "Payment amount",
          text: `${formatCurrency(directDebitInfo.value.paymentAmount, true)} per month`,
        },
        {
          text: `Day ${directDebitInfo.value.paymentDayOfMonth} of every month`,
        },
      ],
    },
    {
      title: "Payment Details",
      editStep: 4,
      content: [
        {
          title: "Bank details",
          text: directDebitInfo.value.accountName,
        },
        {
          text: maskAllExceptLast2(directDebitInfo.value.accountNumber),
        },
      ],
    },
  ])

  function getMoveInTypeformLink(): string {
    TrackingService.sendEvent("move-in-typeform-redirect")
    return ExternalRoute.movingInTypeform
  }

  const moveIn = useSubmitChangeOfTenancyMoveIn()
  const router = useRouter()

  const formatDefaultDateToApi = (date: string) =>
    formatDate(date, "api", "default")

  const submitMoveIn = async () => {
    await moveIn.mutateAsync({
      data: {
        billingAccountId: cotMoveInRequest.value.billingAccountId!,
        firstDateOfResponsibility: formatDefaultDateToApi(
          cotMoveInRequest.value.firstDateOfResponsibility,
        ),
        newOccupantContact: {
          ...newOccupantContact.value,
          dateOfBirth: formatDefaultDateToApi(
            newOccupantContact.value.dateOfBirth,
          ),
        },
        billingAddress: moveInAddress.value,
        supplyAddress: moveInAddress.value,
        directDebitInfo: directDebitInfo.value,
      },
    })
    if (!moveIn.error.value) {
      router.push(Route.cotMoveInComplete)
    }
  }
</script>

<template>
  <StepWizardProgressCard title="Review information">
    <div class="space-y-8">
      <div
        v-for="(stepInformation, index) in information"
        :key="`${stepInformation.title}_${index}`"
        class="flex flex-col gap-3"
      >
        <div class="flex justify-between gap-2">
          <h4>{{ stepInformation.title }}</h4>
          <button
            v-if="stepInformation.editStep != null"
            class="link"
            @click="goToStep(stepInformation.editStep)"
          >
            Edit
          </button>
        </div>
        <div
          v-for="(stepContent, contentIndex) in stepInformation.content"
          :key="`${stepContent.title}_${contentIndex}`"
        >
          <p class="body-text-strong" data-testid="step-content_title">
            {{ stepContent.title }}
          </p>
          <p
            class="label-text text-secondary-500"
            :class="{ '-mt-2': !stepContent.title }"
          >
            {{ stepContent.text }}
          </p>
        </div>
      </div>
    </div>
    <Alert
      v-if="moveIn.isError.value"
      variant="error"
      class="mt-4 w-auto text-left text-sm sm:text-base"
    >
      We encountered an issue while processing your move-in request. Please take
      a moment to
      <Link class="link" target="_blank" :to="getMoveInTypeformLink()"
        >complete this form
      </Link>
      , and we'll take care of the rest.
    </Alert>
    <template #footer>
      <Button
        variant="tonal"
        size="md"
        :loading="moveIn.isPending.value"
        @click="submitMoveIn"
        >Submit
      </Button>
    </template>
  </StepWizardProgressCard>
</template>
