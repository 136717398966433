<script setup lang="ts">
  import { Route } from "@/constants/route"
  import { computed } from "vue"
  import { useRoute } from "vue-router"
  import { Sidebar } from "@/components/sidebar"
  import { PageHeader } from "@/components/ui/page-header"

  import MaintenanceBanner from "@/views/maintenance-banner/MaintenanceBanner.vue"

  const route = useRoute()
  const title = computed(() => (route.meta?.title as string) || "")
  const hideBackButton = computed(() => !!route.meta?.hideBackButton)
</script>

<template>
  <main
    class="container flex flex-col p-6 pb-12 sm:px-10 md:flex-row md:flex-nowrap md:px-14"
  >
    <PageHeader
      v-if="title"
      :title="title"
      :has-back-button="!hideBackButton"
      class="mb-4 mt-2 md:my-6 md:hidden"
      @back="$router.push(Route.home)"
    />
    <Sidebar />
    <div class="flex-1">
      <PageHeader
        v-if="title"
        :title="title"
        :has-back-button="!hideBackButton"
        class="hidden sm:mb-6 md:mt-5 md:flex"
        @back="$router.push(Route.home)"
      />
      <MaintenanceBanner class="mb-4 sm:mb-6" />
      <slot />
    </div>
  </main>
</template>
