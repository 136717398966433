import { useGetAccountStates } from "@/api/account-state/account-state.ts"
import { useGetAccount } from "@/api/account/account.ts"
import { AgreementState, MeterAppointmentState } from "@/api/model"
import { useAuthSession } from "@/authentication/useAuthSession"
import {
  ENABLED_LINKS_ON_CLOSED_ACCOUNT,
  LOGGED_IN_NAVIGATION_ITEMS,
  LOGGED_IN_TABLET_NAVIGATION_ITEMS,
  LOGGED_OUT_NAVIGATION_ITEMS,
  LOGGED_OUT_TABLET_NAVIGATION_ITEMS,
  MOBILE_NAVIGATION_ITEMS,
  NAV_LABELS,
  SIDEBAR_NAVIGATION_ITEMS,
} from "@/constants/navigation.ts"
import { aggregateQueries } from "@/lib/aggregateQueries"
import { useGetAccountId } from "@/lib/useGetAccountId.ts"
import { NavbarItem } from "@/types/Navbar"
import { computed, unref } from "vue"

type Config = {
  isTraditionalSmartEligible: boolean
  isWHDEligible: boolean
  isAccountClosed: boolean
  isFixedRenewalEligible: boolean
  isRenewalEligible: boolean
  isAuthenticated: boolean
}

export function useNavigationItems() {
  const { isAuthenticated } = useAuthSession()

  const customerQuery = useGetAccountId()
  const accountQuery = useGetAccount(customerQuery.accountId)
  const accountStatesQuery = useGetAccountStates(customerQuery.accountId)

  const isAccountClosed = computed(() => !!accountQuery.data.value?.isClosed)
  const isWHDEligible = computed(() => !!accountQuery.data.value?.isWHDEligible)
  const isTraditionalSmartEligible = computed(
    () =>
      accountStatesQuery.data.value?.meterAppointment ===
      MeterAppointmentState.traditionalSmartEligible,
  )
  const isFixedRenewalEligible = computed(
    () =>
      accountStatesQuery.data.value?.agreements ===
      AgreementState.variableTariff,
  )
  const isRenewalEligible = computed(() => {
    const agreementState = accountStatesQuery.data.value?.agreements
    return (
      agreementState === AgreementState.earlyRenewalPeriod ||
      agreementState === AgreementState.standardRenewalPeriod
    )
  })

  const navConfig = computed(() => ({
    isAuthenticated: isAuthenticated.value,
    isWHDEligible: isWHDEligible.value,
    isTraditionalSmartEligible: isTraditionalSmartEligible.value,
    isFixedRenewalEligible: isFixedRenewalEligible.value,
    isRenewalEligible: isRenewalEligible.value,
    isAccountClosed: isAccountClosed.value,
  }))

  const sidebarNavigationItems = computed(() =>
    filterMainNavigationItems(SIDEBAR_NAVIGATION_ITEMS, unref(navConfig)),
  )

  const navigationItems = computed(() =>
    filterMainNavigationItems(
      unref(isAuthenticated)
        ? LOGGED_IN_NAVIGATION_ITEMS
        : LOGGED_OUT_NAVIGATION_ITEMS,
      unref(navConfig),
    ),
  )

  const extendedNavigationItems = computed(() =>
    filterMainNavigationItems(
      unref(isAuthenticated)
        ? LOGGED_IN_TABLET_NAVIGATION_ITEMS
        : LOGGED_OUT_TABLET_NAVIGATION_ITEMS,
      unref(navConfig),
    ),
  )

  const mobileNavigationItems = computed(() => {
    return unref(isAuthenticated)
      ? filterMainNavigationItems(MOBILE_NAVIGATION_ITEMS, unref(navConfig))
      : unref(navigationItems)
  })

  return {
    sidebarNavigationItems,
    navigationItems,
    extendedNavigationItems,
    mobileNavigationItems,
    ...aggregateQueries([customerQuery, accountQuery, accountStatesQuery]),
  }
}

const filterMainNavigationItems = (
  items: NavbarItem[],
  config: Config,
): NavbarItem[] => {
  const {
    isTraditionalSmartEligible,
    isWHDEligible,
    isAccountClosed,
    isFixedRenewalEligible,
    isRenewalEligible,
    isAuthenticated,
  } = config

  const filterItem = (item: NavbarItem): boolean => {
    switch (item.label) {
      case NAV_LABELS.account:
        return isAuthenticated
      case NAV_LABELS.smartMeterBooking:
        return isTraditionalSmartEligible
      case NAV_LABELS.warmHomeDiscount:
        return isWHDEligible
      case NAV_LABELS.fixedRenewal:
        return isFixedRenewalEligible
      case NAV_LABELS.renewal:
        return isRenewalEligible
      default:
        return true
    }
  }

  const processItems = (items: NavbarItem[]): NavbarItem[] => {
    return items.filter(filterItem).map((item) => {
      const processedItem: NavbarItem = { ...item }

      if (item.children?.length) {
        processedItem.children = processItems(item.children)

        processedItem.isDisabled = processedItem.children.every(
          (child) => child.isDisabled,
        )
      }

      if (isAccountClosed) {
        processedItem.isDisabled = item.to
          ? !ENABLED_LINKS_ON_CLOSED_ACCOUNT.includes(item.to)
          : true
      }

      return processedItem
    })
  }

  return processItems(items)
}
