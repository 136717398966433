import dayjs, { Dayjs } from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"

dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)

export const DateFormats = {
  default: "DD/MM/YYYY",
  dashed: "DD-MM-YYYY",
  long: "D MMM YYYY",
  ordinal: "Do MMMM YYYY",
  api: "YYYY-MM-DD",
} as const

export const formatDate = (
  date: Dayjs | string,
  format: keyof typeof DateFormats = "default",
  inputFormat?: keyof typeof DateFormats,
): string => {
  const input = inputFormat ? DateFormats[inputFormat] : undefined
  return dayjs(date, input).format(DateFormats[format])
}
