<script setup lang="ts">
  import { useNavigationItems } from "@/lib/useNavigationItems.ts"
  import UserInfoCard from "@/components/cards/UserInfoCard.vue"
  import SidebarItem from "@/components/sidebar/SidebarItem.vue"
  import { Card } from "@/components/ui/card"

  import SidebarItemsSkeleton from "./SidebarItemsSkeleton.vue"

  const { sidebarNavigationItems, isLoading } = useNavigationItems()
</script>

<template>
  <aside
    class="w-full md:mr-6 md:w-[306px]"
    aria-label="User details and desktop navigation sidebar"
  >
    <UserInfoCard class="mb-4 sm:mb-6" />
    <div class="hidden md:block">
      <Card class="p-6">
        <SidebarItemsSkeleton v-if="isLoading" />
        <ul v-else class="space-y-2" data-testid="sidebar-items">
          <template
            v-for="({ label, to, isDisabled }, index) in sidebarNavigationItems"
            :key="index"
          >
            <SidebarItem
              v-if="to"
              :to="to"
              :label="label"
              :disabled="isDisabled"
            />
          </template>
        </ul>
      </Card>
    </div>
  </aside>
</template>
