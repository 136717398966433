<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import { SupportingLabel } from "@/types/InputComponentTypes"
  import {
    SelectRoot,
    useForwardPropsEmits,
    type SelectRootEmits,
    type SelectRootProps,
  } from "radix-vue"
  import { computed, HTMLAttributes } from "vue"
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from "@/components/ui/form"
  import FormSupportingLabel from "@/components/ui/form/FormSupportingLabel.vue"
  import {
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select"

  const props = defineProps<
    SelectRootProps & {
      class?: HTMLAttributes["class"]
      name: string
      label: string
      items: string[]
      supportingLabels?: SupportingLabel[]
      extraLabel?: string
      placeholder?: string
      keepSupportingLabelsOnError?: boolean
    }
  >()

  const delegatedProps = computed(() => ({
    items: props.items,
    disabled: props.disabled,
  }))
  const emits = defineEmits<SelectRootEmits>()

  const getAriaDescribedBy = (
    formDescriptionId: string,
    formMessageId: string,
    error: string,
  ) => {
    const supportingLabelsIds =
      props.supportingLabels
        ?.map((_, index) => `${formMessageId}-${index}`)
        .join(" ") || ""
    const ids = [
      props.extraLabel ? formDescriptionId : null,
      error ? formMessageId : supportingLabelsIds,
    ]
      .filter(Boolean)
      .join(" ")
    return ids.length ? ids : undefined
  }

  const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <FormField
    v-slot="{ field }"
    :name="name"
    :model-value="modelValue"
    :validate-on-blur="false"
    :validate-on-change="false"
    :validate-on-model-update="false"
  >
    <FormItem v-bind="$attrs">
      <FormControl
        v-slot="{
          error,
          formItemId,
          ariaInvalid,
          formMessageId,
          formDescriptionId,
        }"
      >
        <div class="flex items-baseline justify-between">
          <FormLabel v-if="label" data-testid="form-label">{{
            label
          }}</FormLabel>
          <span
            v-if="extraLabel"
            :id="formDescriptionId"
            class="label-text-strong text-secondary-500"
            data-testid="form-extra-label"
            :aria-label="`${extraLabel}.`"
          >
            {{ extraLabel }}
          </span>
        </div>
        <SelectRoot
          data-testid="select"
          v-bind="{ ...forwarded, ...field }"
          :model-value="field.value"
        >
          <SelectTrigger
            :id="formItemId"
            :error="error"
            :aria-invalid="ariaInvalid"
            :aria-describedby="
              getAriaDescribedBy(formDescriptionId, formMessageId, error)
            "
            :class="cn(props.class)"
          >
            <SelectValue :placeholder="placeholder" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem
                v-for="item in items"
                :key="item"
                :data-testid="`select-item-${item}`"
                :value="item"
              >
                {{ item }}
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </SelectRoot>
        <template
          v-if="
            supportingLabels?.length && (keepSupportingLabelsOnError || !error)
          "
        >
          <FormSupportingLabel
            v-for="(supportingLabel, index) in supportingLabels"
            :id="`${formMessageId}-${index}`"
            :key="`${formMessageId}-${index}`"
            :supporting-label="supportingLabel"
            :data-testid="`form-supporting-label-${index}`"
          >
          </FormSupportingLabel>
        </template>
        <FormMessage />
      </FormControl>
    </FormItem>
  </FormField>
</template>
