<script setup lang="ts">
  import { ExternalRoute } from "@/constants/externalRoute"
  import { useCotMoveIn } from "@/lib/useCotMoveIn"
  import { useStepWizard } from "@/lib/useStepWizard"
  import { AddressFinderModel, AddressNotListed } from "@/types/AddressType"
  import { templateRef } from "@vueuse/core"
  import { ref } from "vue"
  import { AddressFinder } from "@/components/ui/address-finder"
  import { Alert, AlertDescription } from "@/components/ui/alert"
  import Button from "@/components/ui/button/Button.vue"
  import { Link } from "@/components/ui/link"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"

  const { goNext } = useStepWizard()
  const { updateAddress, moveInAddress } = useCotMoveIn()

  const modelValue = ref<AddressFinderModel>()

  const addressFinder = templateRef("addressFinder")
  const onSubmit = async () => {
    await addressFinder.value?.onSubmitPostcode()
    if (modelValue.value && modelValue.value !== AddressNotListed) {
      updateAddress(modelValue.value)
      goNext()
    }
  }
</script>

<template>
  <form
    class="flex w-full flex-wrap justify-center"
    novalidate
    @submit.prevent="onSubmit"
  >
    <StepWizardProgressCard title="New address">
      <p class="mb-4 sm:mb-6">
        What is the address of the property you're moving into?
      </p>
      <AddressFinder
        ref="addressFinder"
        v-model="modelValue"
        :postcode="moveInAddress.postcode"
        filter-by-billing-id
      ></AddressFinder>
      <Alert
        v-if="modelValue === AddressNotListed"
        variant="warning"
        class="mt-4 sm:mt-8"
      >
        <AlertDescription>
          We don't currently supply your address. Not a problem, you can
          <Link class="link" :to="ExternalRoute.quote"
            >get a quote and switch</Link
          >
          your home to So Energy in 4 simple steps!
        </AlertDescription>
      </Alert>
      <template #footer>
        <Button variant="tonal" type="submit" size="md"> Next </Button>
      </template>
    </StepWizardProgressCard>
  </form>
</template>
