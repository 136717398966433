<script setup lang="ts">
  import { useGetAccountStates } from "@/api/account-state/account-state.ts"
  import { useGetMetersByBillingAccountId } from "@/api/account/account.ts"
  import { MeterReading, MeterResponse } from "@/api/model"
  import { ExternalRoute } from "@/constants/externalRoute.ts"
  import { aggregateQueries } from "@/lib/aggregateQueries.ts"
  import { useGetAccountId } from "@/lib/useGetAccountId.ts"
  import dayjs from "dayjs"
  import { computed } from "vue"
  import { Button } from "@/components/ui/button"
  import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "@/components/ui/card"
  import { Icon } from "@/components/ui/icons"

  import MeterReadingsCardSkeleton from "@/views/home/components/meter-readings/MeterReadingsCardSkeleton.vue"

  type MeterReadingValue = {
    fuelName: string
    rateName?: string
    isElectricity: boolean
    value?: string
    date?: string
  }

  const accountIdQuery = useGetAccountId()
  const accountStatesQuery = useGetAccountStates(accountIdQuery.accountId)
  const metersQuery = useGetMetersByBillingAccountId(accountIdQuery.accountId)

  const missingReads = computed(
    () => accountStatesQuery.data.value?.reads !== "submittedRecently",
  )

  const { isLoading, isError, isFetching } = aggregateQueries([
    accountIdQuery,
    accountStatesQuery,
    metersQuery,
  ])

  const formatReading = (reading?: MeterReading) => {
    if (!reading?.readingDate) {
      return "No read submitted"
    }
    return reading.value?.toString()
  }

  const getFormattedReads = (
    meters: MeterResponse[],
    fuelName: "Gas" | "Electricity",
  ) => {
    const readings: MeterReadingValue[] = []
    const isElectricity = fuelName === "Electricity"

    meters.forEach((meter, index) => {
      const fuelNameLabel =
        meters.length > 1 ? `${fuelName} ${index + 1}` : fuelName

      meter.registers?.forEach((register) => {
        const rateName =
          isElectricity && register.rateName && register.rateName !== "standard"
            ? ` (${register.rateName})`
            : ""

        const reading = register.lastNonEstimatedReading

        const readingDateFormatted = reading?.readingDate
          ? dayjs(reading.readingDate).format("DD MMM YYYY")
          : undefined

        readings.push({
          fuelName: fuelNameLabel,
          value: formatReading(reading),
          date: readingDateFormatted,
          isElectricity,
          rateName,
        })
      })
    })

    return readings
  }

  const mappedMeterReads = computed(() => {
    const meters = Array.isArray(metersQuery?.data?.value)
      ? metersQuery?.data?.value
      : []
    const electricityMeters = meters.filter((meter) => meter.isElectricity)
    const gasMeters = meters.filter((meter) => meter.isGas)

    return [
      ...getFormattedReads(electricityMeters, "Electricity"),
      ...getFormattedReads(gasMeters, "Gas"),
    ]
  })
</script>

<template>
  <MeterReadingsCardSkeleton v-if="isLoading" />
  <Card v-else data-testid="meter-readings-card">
    <CardHeader>
      <CardTitle>Meter Readings</CardTitle>
    </CardHeader>
    <CardContent>
      <div v-if="isError && !isFetching">
        <p class="body-text-strong mb-2 flex flex-nowrap items-center gap-2">
          <Icon name="Warning" />
          Failed to load
        </p>
        <p class="label">Something went wrong. Please refresh your browser.</p>
      </div>
      <div v-if="!isError" class="flex flex-col gap-3">
        <p v-if="missingReads">
          It’s been over 30 days since we had a new meter reading.
        </p>
        <p class="body-text-strong">Last reading:</p>
        <dl class="grid grid-cols-[auto_1fr] gap-2">
          <template v-for="reading in mappedMeterReads" :key="reading.fuelName">
            <dt>
              <div class="flex flex-grow gap-1">
                <Icon
                  v-if="reading.isElectricity"
                  name="Electricity"
                  class="mt-[5px] stroke-icon-primary"
                />
                <Icon
                  v-else
                  name="Gas"
                  class="mt-[5px] stroke-icon-secondary"
                />
                <div>
                  <p>{{ reading.fuelName }}</p>
                  <p v-if="reading.rateName">{{ reading.rateName }}</p>
                </div>
              </div>
            </dt>
            <dd>
              <p v-if="reading.value" class="text-right">
                {{ reading.value }}
                <span v-if="reading.date" class="whitespace-nowrap">
                  on {{ reading.date }}</span
                >
              </p>
            </dd>
          </template>
        </dl>
      </div>
    </CardContent>
    <CardFooter class="flex-col sm:flex-col sm:items-end">
      <Button>
        <a :href="ExternalRoute.meterReadings"> Submit Reads </a>
      </Button>

      <Button icon="ChevronRight" size="md" variant="tonal">
        <a :href="ExternalRoute.usage"> View Reading History </a>
      </Button>
    </CardFooter>
  </Card>
</template>
