import { type Config } from "tailwindcss"
import defaultTheme from "tailwindcss/defaultTheme"
import plugin from "tailwindcss/plugin"

export default {
  darkMode: ["class"],
  safelist: ["dark"],
  prefix: "",
  content: ["./src/**/*.{ts,tsx,vue,mdx,jsx}"],

  theme: {
    screens: {
      sm: "640px",
      md: "1024px",
      lg: "1440px",
      xl: "1920px",
    },
    container: {
      center: true,
      screens: {
        xl: "1187px",
      },
    },
    fontFamily: {
      sans: ["var(--font-founders-grotesk)", ...defaultTheme.fontFamily.sans],
      button: ["Arial, sans-serif"],
    },
    extend: {
      boxShadow: {
        DEFAULT: "0px 8px 16px 0px hsla(var(--shadow))",
        sm: "0px 4px 8px 0px hsla(var(--shadow))",
        md: "0px 8px 16px 0px hsla(var(--shadow))",
        lg: "0px 12px 24px 0px hsla(var(--shadow))",
      },
      height: {
        4.5: "1.125rem",
        13: "3.25rem",
      },
      width: {
        4.5: "1.125rem",
      },
      spacing: {
        17: "4.25rem",
        18: "4.5rem",
      },
      colors: {
        border: "hsl(var(--border))",
        input: "hsl(var(--input))",
        ring: "hsl(var(--ring))",
        background: "hsl(var(--secondary-700))",
        foreground: "hsl(var(--foreground))",
        dialogBackground: "hsla(var(--dialog-background))",
        skeleton: "hsl(var(--skeleton))",
        primary: {
          DEFAULT: "hsl(var(--primary-300))",
          100: "hsl(var(--primary-100))",
          200: "hsl(var(--primary-200))",
          300: "hsl(var(--primary-300))",
          400: "hsl(var(--primary-400))",
          500: "hsl(var(--primary-500))",
          600: "hsl(var(--primary-600))",
          700: "hsl(var(--primary-700))",
        },
        secondary: {
          DEFAULT: "hsl(var(--secondary-300))",
          100: "hsl(var(--secondary-100))",
          200: "hsl(var(--secondary-200))",
          300: "hsl(var(--secondary-300))",
          400: "hsl(var(--secondary-400))",
          500: "hsl(var(--secondary-500))",
          600: "hsl(var(--secondary-600))",
          700: "hsl(var(--secondary-700))",
        },
        green: {
          DEFAULT: "hsl(var(--accent-green-300))",
          100: "hsl(var(--accent-green-100))",
          200: "hsl(var(--accent-green-200))",
          300: "hsl(var(--accent-green-300))",
          400: "hsl(var(--accent-green-400))",
          500: "hsl(var(--accent-green-500))",
          600: "hsl(var(--accent-green-600))",
        },
        blue: {
          DEFAULT: "hsl(var(--accent-blue-300))",
          100: "hsl(var(--accent-blue-100))",
          200: "hsl(var(--accent-blue-200))",
          300: "hsl(var(--accent-blue-300))",
          400: "hsl(var(--accent-blue-400))",
          500: "hsl(var(--accent-blue-500))",
          600: "hsl(var(--accent-blue-600))",
        },
        yellow: {
          DEFAULT: "hsl(var(--accent-yellow-300))",
          100: "hsl(var(--accent-yellow-100))",
          200: "hsl(var(--accent-yellow-200))",
          300: "hsl(var(--accent-yellow-300))",
          400: "hsl(var(--accent-yellow-400))",
          500: "hsl(var(--accent-yellow-500))",
          600: "hsl(var(--accent-yellow-600))",
        },
        red: {
          DEFAULT: "hsl(var(--accent-red-300))",
          100: "hsl(var(--accent-red-100))",
          200: "hsl(var(--accent-red-200))",
          300: "hsl(var(--accent-red-300))",
          400: "hsl(var(--accent-red-400))",
          500: "hsl(var(--accent-red-500))",
          600: "hsl(var(--accent-red-600))",
        },
        destructive: {
          DEFAULT: "hsl(var(--accent-red-400))",
          foreground: "hsl(var(--accent-red-100))",
        },
        info: {
          DEFAULT: "hsl(var(--accent-blue-400))",
          foreground: "hsl(var(--accent-blue-100))",
        },
        warning: {
          DEFAULT: "hsl(var(--warning))",
          foreground: "hsl(var(--warning-foreground))",
        },
        utility: {
          DEFAULT: "hsl(var(--utility))",
          foreground: "hsl(var(--utility-foreground))",
        },
        muted: {
          DEFAULT: "hsl(var(--muted))",
          foreground: "hsl(var(--muted-foreground))",
        },
        accent: {
          DEFAULT: "hsl(var(--accent))",
          foreground: "hsl(var(--accent-foreground))",
        },
        popover: {
          DEFAULT: "hsl(var(--popover))",
          foreground: "hsl(var(--popover-foreground))",
        },
        icon: {
          primary: "hsl(var(--primary-500))",
          secondary: "hsl(var(--secondary-400))",
        },
      },
      borderRadius: {
        DEFAULT: "12px",
        xs: "4px",
        sm: "8px",
        md: "16px",
        full: "360px",
      },
      fontSize: {
        xs: ["0.875rem", "1.25rem"],
        sm: ["1rem", "1.5rem"],
        base: ["1.125rem", "1.75rem"],
        lg: ["1.25rem", "1.75rem"],
        xl: [
          "1.5rem",
          {
            lineHeight: "2rem",
            letterSpacing: "-0.00375rem",
          },
        ],
        "2xl": [
          "1.75rem",
          {
            lineHeight: "2rem",
            letterSpacing: "-0.0175rem",
          },
        ],
        "3xl": ["2rem", { lineHeight: "2.25rem", letterSpacing: "-0.04rem" }],
        "4xl": [
          "2.5rem",
          {
            lineHeight: "2.5rem",
            letterSpacing: "-0.025rem",
          },
        ],
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
        "collapsible-down": {
          from: { height: "0" },
          to: { height: "var(--radix-collapsible-content-height)" },
        },
        "collapsible-up": {
          from: { height: "var(--radix-collapsible-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
        "collapsible-down": "collapsible-down 0.2s ease-in-out",
        "collapsible-up": "collapsible-up 0.2s ease-in-out",
      },
      maxWidth: {
        card: "745px",
      },
    },
  },
  plugins: [
    plugin(({ addVariant }) => {
      addVariant("has-hover", "@media (hover: hover) and (pointer: fine)")
      addVariant(
        "no-hover",
        "@media not all and (hover: hover) and (pointer: fine)",
      )
    }),
  ],
} satisfies Config
