<script setup lang="ts">
  import {
    useDeactivateAlert,
    useGetAlertsAndBanners,
  } from "@/api/alerts/alerts"
  import { AlertName, AlertSeverity } from "@/api/model"
  import { useGetAccountId } from "@/lib/useGetAccountId"
  import { cn } from "@/lib/utils.ts"
  import TrackingService from "@/services/TrackingService"
  import { computed, ref, useTemplateRef, watch } from "vue"
  import { Alert as AlertBox, AlertVariants } from "@/components/ui/alert"
  import AlertAction from "@/components/ui/alert/AlertAction.vue"
  import AlertClose from "@/components/ui/alert/AlertClose.vue"
  import { AlertDescription, AlertTitle } from "@/components/ui/alert/index.ts"
  import Button from "@/components/ui/button/Button.vue"

  const severityToVariantMap: {
    [K in AlertSeverity]: AlertVariants["variant"]
  } = {
    [AlertSeverity.info]: "info",
    [AlertSeverity.warning]: "warning",
    [AlertSeverity.critical]: "error",
    [AlertSeverity.success]: "success",
  } as const

  const SUMMARY_LENGTH = 2
  const minimumWrapperHeight = 95
  const isOpen = ref(false)

  const getAccountIdQuery = useGetAccountId()
  const getAlertsAndBannersQuery = useGetAlertsAndBanners(
    getAccountIdQuery.accountId,
  )
  const alertsWrapper = useTemplateRef("alerts-wrapper")

  const hiddenAlerts = ref<string[]>([])

  const allAlertItems = computed(
    () => getAlertsAndBannersQuery.data.value?.alerts ?? [],
  )

  const filteredAlertItems = computed(
    () =>
      allAlertItems.value.filter(
        (alert) => !hiddenAlerts.value.includes(alert.name),
      ) ?? [],
  )

  const isCollapsible = computed(
    () => filteredAlertItems.value.length >= SUMMARY_LENGTH,
  )

  const visibleAlerts = computed(() =>
    isOpen.value
      ? filteredAlertItems.value
      : filteredAlertItems.value.slice(0, SUMMARY_LENGTH),
  )

  const deactivateAlertMutation = useDeactivateAlert({
    mutation: {
      onSuccess: () => {
        getAlertsAndBannersQuery.refetch()
      },
    },
  })

  const alertsWrapperHeight = computed(() => {
    if (!isCollapsible.value) return "auto"

    const height = isOpen.value
      ? alertsWrapper.value?.offsetHeight
      : (alertsWrapper.value?.children[0]?.clientHeight ?? 0) +
        minimumWrapperHeight

    return height + "px"
  })

  const handleAlertClose = (alertName: string) => {
    hiddenAlerts.value.push(alertName)
    if (!getAccountIdQuery.accountId.value) return

    deactivateAlertMutation.mutate({
      accountId: getAccountIdQuery.accountId.value,
      data: { name: alertName as AlertName },
    })

    TrackingService.sendEvent("Alert - Dismiss Click", {
      alertName,
    })
  }

  const onAlertActionClicked = (alertName: string, linkTo: string) => {
    TrackingService.sendEvent("Alert - Action Click", {
      alertName,
      linkTo,
    })
  }

  watch(visibleAlerts, (alerts) => {
    alerts.forEach((alert) => {
      TrackingService.sendEvent("Alert - Impression", {
        alertName: alert.name,
      })
    })
  })
</script>

<template>
  <div class="relative">
    <div
      v-if="filteredAlertItems.length"
      class="overflow-hidden transition-all duration-700 ease-out"
      :style="{ maxHeight: alertsWrapperHeight }"
    >
      <div ref="alerts-wrapper" class="space-y-4 sm:space-y-6">
        <div
          v-for="alert in filteredAlertItems"
          ref="index === 0 ? 'firstAlertItem' : null"
          :key="alert.title"
        >
          <AlertBox
            :variant="severityToVariantMap[alert?.severity || 'warning']"
          >
            <AlertTitle>{{ alert.title }}</AlertTitle>
            <AlertClose
              v-if="alert.isDismissible"
              data-dd-action-name="Dismiss alert"
              @close="() => handleAlertClose(alert.name)"
            />
            <AlertDescription>
              {{ alert.subtitle }}
            </AlertDescription>
            <template v-if="alert.buttonLink" #action>
              <AlertAction
                data-dd-action-name="Alert action"
                :label="alert.buttonText || ''"
                :action-url="alert.buttonLink"
                @click="onAlertActionClicked(alert.name, alert.buttonLink)"
              />
            </template>
          </AlertBox>
        </div>
      </div>
    </div>
    <div
      v-if="isCollapsible"
      :class="
        cn(
          'absolute bottom-0 z-10 h-[0px] w-full bg-gradient-to-t from-secondary-200 to-transparent transition-all duration-700 ease-out',
          !isOpen && ' h-8',
        )
      "
    ></div>
  </div>
  <div v-if="isCollapsible" class="text-center">
    <Button variant="outline" @click="isOpen = !isOpen">{{
      isOpen ? "Hide Notifications" : "Show All Notifications"
    }}</Button>
  </div>
</template>
