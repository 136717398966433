<script setup lang="ts">
  import { MINIMUM_PAYMENT_AMOUNT } from "@/constants/payments"
  import { useCotMoveIn } from "@/lib/useCotMoveIn"
  import { useStepWizard } from "@/lib/useStepWizard"
  import { toTypedSchema } from "@vee-validate/zod"
  import { useForm } from "vee-validate"
  import { computed, ref } from "vue"
  import * as z from "zod"
  import Button from "@/components/ui/button/Button.vue"
  import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
  } from "@/components/ui/dialog"
  import { NumberField } from "@/components/ui/inputs/number-field"
  import { Select } from "@/components/ui/select"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"

  const { goNext } = useStepWizard()
  const { directDebitInfo, updateDirectDebitInfo } = useCotMoveIn()

  const minimumPaymentAmountMessage = `£${MINIMUM_PAYMENT_AMOUNT} is the minimum monthly payment amount`

  const formSchema = toTypedSchema(
    z.object({
      paymentAmount: z.coerce
        .number({
          errorMap: (_, ctx) => {
            if (isNaN(ctx.data)) {
              return { message: "This field is required" }
            }

            return { message: "Please enter a valid number" }
          },
        })
        .max(99999, "Value cannot exceed 5 digits")
        .refine(
          (val) => val && val >= MINIMUM_PAYMENT_AMOUNT,
          `Value must be greater than or equal to £${MINIMUM_PAYMENT_AMOUNT}`,
        ),
      paymentDayOfMonth: z.coerce
        .number({
          required_error: "Please select day of your payment",
          invalid_type_error: "Please select day of your payment",
        })
        .min(1, "Please select day of your payment"),
    }),
  )

  const isMissedPaymentModalOpen = ref(false)

  const { handleSubmit, values } = useForm({
    validationSchema: formSchema,
  })

  const selectDateSupportingLabel = computed(() => {
    return values.paymentDayOfMonth && values.paymentDayOfMonth > 0
      ? [
          {
            label: `Day ${values.paymentDayOfMonth} of every month`,
          },
        ]
      : undefined
  })

  const monthDays = computed(() => {
    return Array.from({ length: 28 }, (_, i) => (i + 1).toString())
  })

  const paymentDate = computed(() =>
    monthDays.value.includes(
      directDebitInfo.value?.paymentDayOfMonth.toString() || "",
    )
      ? directDebitInfo.value?.paymentDayOfMonth.toString()
      : undefined,
  )

  const onSubmit = handleSubmit(async (data) => {
    updateDirectDebitInfo(data)
    goNext()
  })
</script>

<template>
  <form
    class="flex w-full flex-wrap justify-center"
    novalidate
    @submit="onSubmit"
  >
    <StepWizardProgressCard title="Monthly payment">
      <div class="flex flex-col gap-8">
        <p>
          We can't predict your energy usage based on the previous occupiers.
          Your energy use and costs may differ from before, so we may need to
          adjust your Direct Debit. We'll let you know if this is necessary.
        </p>
        <NumberField
          :model-value="directDebitInfo.paymentAmount"
          name="paymentAmount"
          label="Enter Amount"
          :default-value="MINIMUM_PAYMENT_AMOUNT"
          class="max-w-[348px]"
          has-icon
          :supporting-labels="[
            {
              label: minimumPaymentAmountMessage,
            },
          ]"
        />
        <Select
          name="paymentDayOfMonth"
          placeholder="Select day"
          class="max-w-[348px]"
          :model-value="paymentDate"
          label="Which day should we take payment?"
          :supporting-labels="selectDateSupportingLabel"
          :items="monthDays"
        />
        <div
          data-testid="miss-payment-modal"
          @click="isMissedPaymentModalOpen = true"
        >
          <p class="link cursor-pointer">What happens if I miss a payment?</p>
        </div>
      </div>
      <Dialog v-model:open="isMissedPaymentModalOpen">
        <DialogContent>
          <DialogHeader>
            <DialogTitle>What happens if I miss a payment?</DialogTitle>
          </DialogHeader>
          <p>
            If you miss a payment or cancel your Direct Debit, this may incur a
            charge of £20.
          </p>
        </DialogContent>
      </Dialog>
      <template #footer>
        <Button variant="tonal" type="submit" size="md"> Next</Button>
      </template>
    </StepWizardProgressCard>
  </form>
</template>
