import { isAbsoluteUrl } from "./isAbsoluteUrl"

export const getPublicWebsiteUrl = (): string => {
  const publicWebsiteUrl = import.meta.env.VITE_PUBLIC_WEBSITE_URL
  return `${publicWebsiteUrl}/`
}
export const getMyAccountUrl = (): string => {
  if (import.meta.env.DEV) {
    return `${getPublicWebsiteUrl()}myaccount/`
  }
  return "/myaccount/"
}

const pathToWebsiteUrl = (url: string) => {
  const reg = /^\/?myaccount\/?(.*)/i
  const matches = reg.exec(url)
  if (matches) {
    return getMyAccountUrl() + matches[1]
  }
  return getPublicWebsiteUrl() + url
}

export const ensureResolvedWebsiteUrl = (url: string) => {
  if (isAbsoluteUrl(url)) {
    return url
  }
  return pathToWebsiteUrl(url)
}
